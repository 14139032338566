(function () {
    'use strict';

    var TOOL_TYPES = ['OLTS', 'Inspection', 'Certification'];

    angular.module('aerosApp').factory('newTestSetupModal', TestSetupModal).factory('TestSetupModal', TestSetupModal).factory('TestSetupFiberModeModal', TestSetupFiberModeModal).controller('TestSetupModalCtrl', TestSetupModalCtrl).run(listenEvents);

    listenEvents.$inject = ['TestSetupModal', 'appDispatcher', 'TestSetupFiberModeModal'];
    function listenEvents(TestSetupModal, appDispatcher, TestSetupFiberModeModal) {
        appDispatcher.on('addToolLibrary', openModal);
        function openModal(e, model) {
            return isToolSupported(model) && (
                model.fiberMode ? openFormModal(model) :
                        TestSetupFiberModeModal.open(model).then(openFormModal));
        }

        function openFormModal(model) {
            TestSetupModal.open(_.defaults(model, {
                type: model.toolType,
            }));
        }

        function isToolSupported(model) {
            return ~TOOL_TYPES.indexOf(model.toolType)
        }
    }

    TestSetupModal.$inject = ['$uibModal', '$rootScope', 'aerosApi'];
    function TestSetupModal($uibModal, $rootScope, aerosApi) {

        return {open: open};

        function open(testSetup) {
            var testTemplate = getTestTemplate(testSetup);
            return $uibModal.open({
                size: "md",
                templateUrl: '/static/templates/dashboard/project/modal/testSetups/' + testTemplate + '.html',
                controller: 'TestSetupModalCtrl',
                resolve: {
                    testTemplate: function () {
                        return testTemplate
                    },
                    testSetup: function () {
                        return testSetup
                    },
                    testLimits: getTestLimits,
                    config: ['configService', function (configService) {
                        return configService.getPromise();
                    }]
                }
            }).result;
        }

        function getTestLimits() {
            return aerosApi.getTestLimits($rootScope.orgId)
                .then(function (data) {
                    return data.limits.filter(function(item) {
                        return !item.obsolete;
                    });
                });
        }
    }

    TestSetupModalCtrl.$inject = ['$scope', '$uibModalInstance', 'aerosApi', 'testTemplate', 'testSetup', 'config',
        'fiberTypes', 'TestSetupResource', 'appDispatcher', 'testLimits', 'Notification'];
    function TestSetupModalCtrl($scope, $uibModalInstance, aerosApi, testTemplate, testSetup, config, fiberTypes,
                                TestSetupResource, appDispatcher, testLimits, Notification) {

        var resourceParams = {
            toolType: testTemplate,
            orgId: $scope.orgId
        };

        if (testSetup.type == 'INSPECTION') {
            $scope.testSetupRadioInit = testSetup.ends || 'Both';
            $scope.passFailStandardRadioInit = testSetup.testLimitKey || 'None';
        }

        $scope.testMethods = config.getTestMethods();
        $scope.testDirections = config.getTestDirections();
        $scope.passFailStandards = config.getPassFailStandards();

        $scope.testSetup = _.defaults(testSetup, {
            runOLTSTest: true,
            runORLTest: false,
            fiberMode: 'Singlemode',
            stdType:  'All',
            catType:  'All',
            certFiberType:  'All'
        }, resourceParams);

        $scope.filterCertType = filterCertType;

        function filterCertType(val) {
            // This method is triggered if user changes any certification test radial buttons
            if ($scope.testSetup.stdType ==='Application' && val==='Application') {
                $scope.testSetup.catType = 'All' ;
                $scope.testSetup.certFiberType = 'All' ;
            }
            if ($scope.testSetup.stdType ==='Cabling' && val==='Cabling') {
                $scope.testSetup.catType = 'All' ;
                $scope.testSetup.certFiberType = 'All' ;
            }
            if ($scope.testSetup && $scope.testSetup.stdType) {
                // In some cases we will have to remove some filters
                if ($scope.testSetup.catType != 'Ethernet') {
                    $scope.testSetup.certFiberType = 'All' ;
                }
                if ($scope.testSetup.stdType === 'All' || $scope.testSetup.stdType === 'Custom' ) {
                    $scope.testSetup.catType = 'All' ;
                    $scope.testSetup.certFiberType = 'All' ;
                }

                $scope.testLimits = _.chain(testLimits).filter(byCustomLimitForOLTS).filter(byFiberMode).
                filter(byStdType).filter(byCatType).filter(byCertFiberType).value();
            }} ;


        $scope.testLimits = _.chain(testLimits).filter(byCustomLimitForOLTS).filter(byFiberMode).
        filter(byStdType).filter(byCatType).filter(byCertFiberType).value();

        $scope.setCertFilter = setCertFilter ;
        function setCertFilter(limitName) {
            // This method will set certification filters
            // in case of existing certification test is being copied
            // to create a new certification test

            if (!limitName) {
                return ;
            }
            var thisRule = $scope.testLimits.filter(function(item) {
                return item.name === $scope.testSetup.rule;
            });
            if (thisRule[0]) {
                if (thisRule[0].stdType) {
                    $scope.testSetup.stdType = thisRule[0].stdType ;
                    if (thisRule[0].catType) {
                        $scope.testSetup.catType = thisRule[0].catType ;
                        if (thisRule[0].certFiberType) {
                            $scope.testSetup.certFiberType ;
                        }
                    }
                }
            }
            if ($scope.testSetup.stdType != 'All') {
                $scope.testLimits = _.chain(testLimits).filter(byCustomLimitForOLTS).filter(byFiberMode).
                filter(byStdType).filter(byCatType).filter(byCertFiberType).value();
            }
        } ;

        setCertFilter($scope.testSetup.rule) ;

        $scope.cancel = $uibModalInstance.dismiss.bind($uibModalInstance);
        $scope.saveTestSetup = saveTestSetup;

        function saveTestSetup() {
            TestSetupResource(resourceParams)
                .create($scope.testSetup).$promise
                .then(appDispatcher.emit('toolLibraryCreateSuccess'))
                .then($uibModalInstance.close)
                .catch(
                    function(result) {
                        Notification.error(result.data.message);
                        appDispatcher.emit('toolLibraryCreateError');
                    });
        }

        // filters
        function byCustomLimitForOLTS(limit) {
            return !isOLTS() || limit.isCustomTestLimit === true;
        }

        function byFiberMode(limit) {
            return limit.testPort === (testSetup.fiberMode || 'Singlemode');
        }

        function byStdType(limit) {
            return (
                ((testSetup.toolType != 'Certification') &&           // Tool created in "OrgToolBar
                (testSetup.toolType != 'singleModeCertification') &&  // AEROS-3694 - the tool created in "Project"
                    (testSetup.toolType != 'multiModeCertification')) // the tool created in "Project"
                || (testSetup.stdType === 'All') ||
                (limit.stdType === testSetup.stdType) ||
                ((testSetup.stdType === 'Custom') && (limit.isCustomTestLimit))) ;
        }

        function byCatType(limit) {
            return (
                ((testSetup.toolType != 'Certification') &&                 // Tool created in "OrgToolBar
                    (testSetup.toolType != 'singleModeCertification') &&    // AEROS-3694 - the tool created in "Project"
                    (testSetup.toolType != 'multiModeCertification'))       // the tool created in "Project"
                || (testSetup.catType === 'All')||
                (limit.catType === testSetup.catType && testSetup.catType !='Other') ||
                (testSetup.catType ==='ATM' && limit.name.startsWith('ATM')) ||
                (testSetup.catType ==='Other' && limit.name.startsWith('AS/NZS')) ||
                (testSetup.catType ==='Other' && limit.catType != testSetup.catType &&
                    !limit.name.startsWith('ATM') &&
                    limit.catType != 'Ethernet' &&
                    limit.catType != 'FibreChannel' &&
                    limit.catType != 'TIA' &&
                    limit.catType != 'ISO' &&
                    limit.catType != 'EN'));
        }

        function byCertFiberType(limit) {
            return (
                ((testSetup.toolType != 'Certification') &&                 // Tool created in "OrgToolBar
                    (testSetup.toolType != 'singleModeCertification') &&    // AEROS-3694 - the tool created in "Project"
                    (testSetup.toolType != 'multiModeCertification'))       // the tool created in "Project"
                || (!limit.fiberType) || testSetup.certFiberType === 'All' || limit.fiberType.startsWith(testSetup.certFiberType)) ;
        }

        function findType(type) {
            var found = fiberTypes.find(function (ftype) {
                return ftype.id === type;
            });

            return found && found.type;
        }

        function isOLTS() {
            return !!~testTemplate.indexOf('OLTS');
        }
    }

    function TestSetupFiberModeModal($uibModal) {
        return {
            open: open
        };

        function open(model) {
            var fiberModes = [
                    {name: 'Single-mode', value: 'Singlemode'},
                    {name: 'Multimode', value: 'Multimode'}
                ],
                modal = $uibModal.open({
                size: "md",
                templateUrl: '/static/templates/tools/testSetupFiberModeModal.html',
                controller: ['$scope', function ($scope) {
                    $scope.fiberModes = fiberModes;
                    $scope.model = _.defaults(model/*, {
                        fiberMode: fiberModes[0].value
                    }*/);
                }]
            });
            return modal.result;
        }
    }

    function getTestTemplate(testSetup) {
        return testSetup.type === 'ORL' ? 'orl' :
            getMode(testSetup.fiberMode) +
            (testSetup.type.toUpperCase() === 'INSPECTION' ? 'Efi' : testSetup.type);
    }

    function getMode(mode) {
        return mode === 'Singlemode' ? 'singleMode' :
            mode === 'Multimode' ? 'multiMode' :
                undefined;
    }

}());
